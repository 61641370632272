@import '../../../styles/colors';

.smg-form-info {
    font-family: 'radikal', sans-serif;
    font-size: 15px;
    line-height: 19px;
    color: $grey80;
    background-color: $trafficLightYellow;
    padding: 4px;
    min-height: 23px;
    text-decoration: line-through;
}
