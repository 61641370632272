@import "../../../styles/colors";

.smg-edit-status-filter {
    display: flex;
}
.smg-edit-status-filter__toggle--new {
    background: $trafficLightGreen;
}
.smg-edit-status-filter__toggle--edited {
    background: $trafficLightYellow;
}
.smg-edit-status-filter__toggle--invalid {
    background: $trafficLightRed;
}
.smg-edit-status-filter__toggle--original {
    background: $ciBlue3;
}
