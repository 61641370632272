@import '../../../styles/colors';

.auth-overlay {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    // position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    // background-color: $ciBlue3;

    .auth-overlay__dialog {
        margin-top: 200px;
        width: 400px;
        padding: 20px;
        border: 1px solid #fff;
        color: #fff;
    }

    a,
    a span {
        font-family: 'radikal', sans-serif;
        font-size: 14px !important;
        line-height: 20px !important;
        font-weight: 400 !important;
        color: #fff !important;
        text-decoration: underline;
    }
}
