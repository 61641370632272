@import '../../styles/colors';

.smg-costcenter-editor__dropdown {
    .ui.selection.dropdown {
        width: 100%;
        height: auto;
        border: 1px solid $grey80;
        background: transparent;
        border-radius: 0;
        color: $grey80;
        font-family: radikal;
        font-size: 16px;
        line-height: 20px;
        padding: 7.5px;
        &:hover {
            background-color: rgba(239, 239, 239, 0.4);
            border: 1px solid $grey80;

            cursor: pointer;
        }
        .default.text {
            color: $grey80;
        }
        .active.default.text {
            color: $grey80;
        }
        input {
            color: $grey80;
            padding: 10px;
        }
    }
}
