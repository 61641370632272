@import "../../../styles/colors";

.smg-filter-toggle {
    border: none;
    outline: none;
    cursor: pointer;
    margin-right: 8px;
    padding: 5px 10px;
    
    &:hover {
        background: $ciBlue4;
    }

    &:active {
        background: $ciBlue2;
    }

    &--inactive {
        opacity: .4;
        .smg-filter-toggle__label {
            color: $grey80;
        }

        &:hover {
            opacity: 1;
            
            .smg-filter-toggle__label {
                color: #fff;
            }
        }
    }

    .smg-filter-toggle__label {  
        font-size: 14px;
        line-height: 20px;
        font-weight: 700;
        color: #fff;
        text-transform: uppercase;
    }
}
