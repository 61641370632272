@import '../../../styles/colors';

.smg-form-label {
    padding-bottom: 4px;
    min-height: 23px;

    span {
        font-family: 'radikal', sans-serif;
        font-size: 15px;
        line-height: 19px;
        font-weight: 700;
        color: $ciBlue1;
        white-space: nowrap;
    }

    &--align-right {
        display: flex;
        justify-content: flex-end;
    }

    &--large {
        span {
            font-size: 25px;
        }
    }
}
