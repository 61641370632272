@import "../../../styles/colors";

.smg-table-body-row {
    display: flex;
    flex-flow: row nowrap;
    padding: 0 5px;
    background: #fff;
    box-shadow: $boxShadow;

    &--clickable {
        cursor: pointer;

        &:hover {
            background: $grey20;
        }
    }

    &--active {
        background: $grey20;
    }

    &--modified {
        background: $trafficLightYellow;
    }

    &--error {
        background: $trafficLightRed;
    }

    &--new {
        background: $trafficLightGreen;
    }
}
