@import "../../../styles/colors";

.smg-basic-button-group {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    &.wrap {
        flex-wrap: wrap;
        margin-top: -8px;
        .smg-basic-button-group__button {
            margin-top: 8px;
        }
    }
}

.smg-basic-button-group__button {
    margin-right: 8px;

    &:last-child {
        margin-right: 0;
    }
}
