@font-face {
  font-family: 'radikal';
  src: url('../assets/fonts/radikal_light.woff2') format('woff2');
  font-style: normal;
  font-weight: 400;
  font-display: auto
}

@font-face {
  font-family: 'radikal';
  src: url('../assets/fonts/radikal_medium.woff2') format('woff2');
  font-style: normal;
  font-weight: 700;
  font-display: auto
}