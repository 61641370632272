
.smg-headline-one {
    font-family: "radikal", sans-serif;
    // font-size: 35px; // CI-konform
    // line-height: 43px; // CI-konform
    font-size: 44.8px; // old style
    line-height: 57.6px;  // old style
    font-weight: 700;
    color: #fff;
    margin: 0;
}
