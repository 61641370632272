@import '../../../styles/colors';

.smg-basic-input {
  display: flex;
  flex-flow: row nowrap;
  border: 1px solid $grey80;
  box-sizing: border-box;

  &:hover {
    background-color: rgba(239, 239, 239, 0.4);
  }

  input {
    flex-grow: 1;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 100%;
    height: 48px;
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    color: $grey80;
    font-family: 'radikal', sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding: 0 10px;
  }

  textarea {
    width: 100%;
    height: 88px;
    border: none !important;
    outline: none !important;
    background-color: transparent !important;
    color: $grey80;
    font-family: 'radikal', sans-serif;
    font-size: 16px;
    line-height: 20px;
    padding: 14px 10px;
  }

  &--layout-white {
    border-color: #fff !important;

    &:hover {
      background-color: $ciBlue2;
    }

    &:focus {
      background-color: $ciBlue3;
    }

    input {
      background-color: transparent !important;
      color: #fff !important;
    }
  }

  &--error {
    border-color: $trafficLightRed !important;
  }

  .smg-basic-input__password-toggle {
    flex: 0 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    user-select: none;
    cursor: pointer;

    img {
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
  }
}
