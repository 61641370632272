@import '../../../styles/colors';

.smg-static-field {
    width: 100%;
    box-sizing: border-box;
    padding: 15px 11px;
    min-height: 50px;
    background-color: $grey20;
    overflow: hidden;

    font-family: 'radikal', sans-serif;
    font-size: 16px;
    line-height: 20px;
    color: $ciBlue1;

    a,
    a:active,
    a:link,
    a:visited {
        font-size: 16px;
        line-height: 20px;
        color: $ciBlue2;
        word-break: break-all;
    }

    a:hover {
        color: $ciBlue1;
        text-decoration: underline;
    }

    b,
    strong {
        font-weight: 700;
    }

    &--transparent {
        background-color: transparent;
    }

    &--align-right {
        display: flex;
        justify-content: flex-end;
    }

    &--no-padding {
        padding: 10px 0;
        min-height: 20px;
    }
}
