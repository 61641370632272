@import '../../styles/colors';

.ui.label {
  background: white;
  border: none;
  box-shadow: 0 1px 4px 0 rgba(212, 154, 154, 0.3);
  color: #505050;
  border-radius: 2px;
}

.recharts-cartesian-axis-tick {
  font-size: 0.75rem;
}
