@import "../../../styles/colors";

.smg-advice {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    background: $ciBlue3;
    padding: 10px 15px;

    .smg-advice__text {
        flex-grow: 1;
        font-family: "radikal", sans-serif;
        font-size: 15px;
        line-height: 20px;
        font-weight: 400;
        color: #fff;
    }

    .smg-advice__close {
        display: flex;
        align-items: center;
        padding-left: 20px;
        cursor: pointer;

        img {
            width: 14px;
            height: 14px;
            vertical-align: top;
        }
    }

    &--success {
        background: $trafficLightGreen;
    }

    &--error {
        background: $trafficLightRed;
    }

    &--info {
        background: $trafficLightYellow;
    }
}
