
$trafficLightGreen: #79D647;
$trafficLightYellow: #ffb700;
$trafficLightRed: #f43636;


$grey80: #4b4b4b;

$ciBlue1: #001e49;
$ciBlue2: #12446c;
$ciBlue3: #4c668b;
$ciBlue4: #829db6;
$ciBlue5: #becfdd;
$ciRed: #e73838;

$grey80: #585858;
$grey60: #888888;
$grey40: #b2b2b2;
$grey20: #dadada;

$acTurquoise: #00a29d;
$acTurquoiseDark: #005755;
$acTurquoise80: #22b4b1;
$acTurquoise60: #7cc8c4;
$acTurquoise40: #afdcd9;
$acTurquoise20: #d9edec;

$boxShadow: 0 1px 4px 0 rgba(0, 0, 0, 0.3);
