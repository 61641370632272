@import '../../../styles/colors';

.smg-copy-text-m {
    font-family: 'radikal', sans-serif;
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
}

.smg-copy-text-m-bold {
    font-weight: 600;
}
