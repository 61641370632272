@import '../../styles/colors';

.checkbox {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;

    button {
        width: 30px;
        height: 30px;
        border: 1px solid $grey80;
        box-sizing: border-box;
        padding: 0;
        background: transparent;
        color: $grey80;
        cursor: pointer;
    }

    label {
        font-family: "radikal", sans-serif;
        font-size: 16px;
        line-height: 20px;
        padding-left: 10px;
        color: $grey80;
    }

    &--disabled {
        button {
            color: $grey80;
            background-color: $grey20;
            border: 1px solid $grey20;
            cursor: default;
        }
    }

    &--white {
        button {
            border-color: #fff;
            color: #fff;
        }
        label {
            color: #fff;
        }
    }

    &--large {
        padding-top: 26px;
        button {
            width: 50px;
            height: 50px;
            svg {
                transform: scale(1.7)
            }
        }
    }
}
