@import "../../../styles/colors";

.smg-form-cell {
    width: 100%;
    padding: 0 5px 20px 0;
    box-sizing: border-box;

    &--cols-1 {
        width: 8.3333333333%;
    }

    &--cols-1-5 {
        width: 12.5%;
    }

    &--cols-2 {
        width: 16.6666666666%;
    }

    &--cols-2-5 {
        width: 20.8333333333%;
    }

    &--cols-3 {
        width: 25%;
    }

    &--cols-4 {
        width: 33.3333333333%;
    }

    &--cols-5 {
        width: 41.6666666666%;
    }

    &--cols-6 {
        width: 50%;
    }

    &--cols-7 {
        width: 58.3333333333%;
    }

    &--cols-8 {
        width: 66.6666666666%;
    }

    &--cols-9 {
        width: 75%;
    }

    &--cols-10 {
        width: 83.3333333333%;
    }

    &--cols-10-5 {
        width: 87.5%;
    }

    &--cols-11 {
        width: 91.6666666667%;
    }

    &--cols-12 {
        width: 100%;
    }

    &--align-right {
        display: flex;
        justify-content: flex-end;
    }
}

