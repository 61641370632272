@import "../../../styles/colors";

.smg-form-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin: 0 -5px -20px 0;
    box-sizing: border-box;
}

