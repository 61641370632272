@import '../../../styles/colors';

.smg-table-body-cell {
  flex-grow: 0;
  overflow: hidden;

  .smg-table-body-cell__inner {
    padding: 14px 10px;
  }

  &--wide {
    .smg-table-body-cell__inner {
      padding: 20px 10px;
    }
  }
}

.smg-table-body-cell__text {
  font-family: 'radikal', sans-serif;
  font-size: 14px;
  line-height: 20px;
  hyphens: auto;
  overflow-wrap: break-word;
  color: $ciBlue1;
  &--old {
    text-decoration: line-through;
  }
}

.smg-table-body-cell__icon {
  display: flex;
  align-items: center;
  width: 18px;
  height: 18px;

  svg {
    width: 18px;
    height: 18px;
    vertical-align: top;
    color: $ciBlue3;
  }
}

.smg-table-body-cell__icon-small {
  display: flex;
  align-items: center;
  height: 20px;

  svg {
    width: 12px;
    height: 12px;
    vertical-align: top;
    color: #4b4b4b;
  }
}

.smg-table-body-cell__children {
  display: flex;
  justify-content: center;
  align-items: center;
}
