.smg-table-header-cell {
    flex-grow: 0;
    user-select: none;
}

.smg-table-header-cell__inner {
    display: flex;
    justify-content: flex-start;
    padding: 0 10px;
}

.smg-table-header-cell__label {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: top;
    color: #fff;

    span {
        font-family: 'radikal', sans-serif;
        font-size: 14px;
        line-height: 22px;
        font-weight: 700;
    }

    &--clickable {
        cursor: pointer;
    }

    &--dark {
        color: #000;
    }
}

.smg-table-header-cell__icon-sorting {
    margin-left: 5px;
    height: 22px;
    display: flex;
    align-items: center;

    img {
        width: 10px;
        height: auto;
    }
}
