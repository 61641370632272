.smg-view-base-data {
    display: block;
}

.smg-view-base-data__text {
    font-family: "radikal", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: #fff;
}

.smg-view-base-data__toolbar {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    >div {
        margin-bottom: 10px;
        &.smg-view-base-data__toolbar-right {
            margin-bottom: 0;
        }
    }
}

.smg-view-base-data__toolbar-filter {
    flex: 1 0 auto;
    margin-left: 8px;
}

.smg-view-base-data__toolbar-filter:first-child {
    margin-left: 0;
}

.smg-view-base-data__toolbar-right {
    flex: 1 1 auto;
    display: flex;
    flex-flow: row wrap;
    >div {
        margin-left: 8px;
        margin-bottom: 10px;
        flex: 0 0 auto;
        &:first-child {
            flex: 1 0 auto;
        }
    }
    .smg-headline-three {
        line-height: 50px;
    }
}
